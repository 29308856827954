.generate-multibar-colors(@n, @i: 1) when (@i =< @n) {
  .nvd3 .nv-multibarHorizontal > g > g.nv-groups > g > g:nth-child(@{i}) > rect {
    fill: lighten(@chart-bar-color, (@i * 2%)) !important;
  }

  .nvd3 .nv-multibar > g > g.nv-groups > g:nth-child(1) > rect:nth-child(@{i}) {
    fill: lighten(@chart-bar-color, (@i * 2%)) !important;
  }

  .nvd3 .nv-multibar > g > g.nv-groups > g:nth-child(1) > rect:nth-child(@{i}) {
    fill: lighten(@chart-bar-color-alt, (@i * 2%)) !important;
  }

  .generate-multibar-colors(@n, (@i + 1));
}

.generate-week-colors(@n, @i: 1) when (@i =< @n) {
  .weeklyChart .nvd3 .nv-discretebar > g > g.nv-groups > g > g:nth-child(@{i}) > rect {
    fill: lighten(@chart-bar-color, (@i * 7%)) !important;
  }

  .generate-week-colors(@n, (@i + 1));
}

.generate-day-colors(@n, @i: 1) when (@i =< @n) {
  .dailyChart .nvd3 .nv-discretebar > g > g.nv-groups > g > g:nth-child(@{i}) > rect {
    fill: lighten(@chart-bar-color, (@i * 2%)) !important;
  }

  .generate-day-colors(@n, (@i + 1));
}
