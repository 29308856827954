@banner-url: url("../../img/banner-grey-dark.png");

@gray-base: rgb(0, 0, 0);
@gray-darker: rgb(38, 44, 46);
@gray-dark: rgb(58, 63, 68);
@gray: rgb(82, 87, 92);
@gray-light: rgb(122, 130, 136);
@gray-lighter: rgb(70, 70, 70);
@brand-primary: @gray-light;
@color-darken: 10%;
@brand-success: darken(rgb(98, 196, 98), @color-darken);
@brand-info: darken(rgb(79, 169, 194), @color-darken);
@brand-warning: darken(rgb(194, 115, 6), @color-darken / 2);
@brand-danger: darken(rgb(194, 78, 76), @color-darken);
@brand-danger-message: lighten(@brand-danger, 20%);
@body-bg: @gray-darker;
@text-color: rgb(200, 200, 200);
@link-color: rgb(255, 255, 255);
@link-hover-color: rgb(255, 255, 255);
@link-hover-decoration: underline;
@component-active-color: rgb(255, 255, 255);
@component-active-bg: transparent;
@table-bg: lighten(@body-bg, 3%);
@table-bg-accent: lighten(@table-bg, 3%);
@table-bg-hover: lighten(@table-bg, 12%);
@table-bg-active: @table-bg-hover;
@table-border-color: darken(@gray-darker, 5%);
@btn-font-weight: normal;
@btn-default-color: rgb(255, 255, 255);
@btn-default-bg: @navbar-default-bg;
@btn-default-active-bg: darken(@btn-default-bg, 10%);
@btn-default-border: @btn-default-bg;
@btn-primary-color: @btn-default-color;
@btn-primary-bg: @brand-primary;
@btn-primary-border: @btn-primary-bg;
@btn-success-color: @btn-default-color;
@btn-success-bg: @brand-success;
@btn-success-border: @btn-success-bg;
@btn-info-color: @btn-default-color;
@btn-info-bg: @brand-info;
@btn-info-border: @btn-info-bg;
@btn-warning-color: @btn-default-color;
@btn-warning-bg: @brand-warning;
@btn-warning-border: @btn-warning-bg;
@btn-danger-color: @btn-default-color;
@btn-danger-bg: @brand-danger;
@btn-danger-border: @btn-danger-bg;
@btn-link-disabled-color: @gray-light;
@code-bg: #868686;
@code-color: #004414;
@input-bg: rgb(31, 35, 40);
@input-bg-disabled: @gray-lighter;
@input-color: @text-color;
@input-border: rgb(204, 204, 204);
@input-border-focus: rgb(102, 175, 233);
@input-color-placeholder: @gray-light;
@legend-color: @text-color;
@legend-border-color: darken(@gray-darker, 5%);
@input-group-addon-bg: darken(@gray-darker, 10%);
@input-group-addon-border-color: @input-border;
@dropdown-bg: @gray-dark;
@dropdown-border: rgba(0, 0, 0, 0.15);
@dropdown-fallback-border: @gray-darker;
@dropdown-divider-bg: @gray-darker;
@dropdown-link-color: @text-color;
@dropdown-link-hover-color: rgb(255, 255, 255);
@dropdown-link-hover-bg: @dropdown-link-active-bg;
@dropdown-link-active-color: rgb(255, 255, 255);
@dropdown-link-active-bg: @gray-darker;
@dropdown-link-disabled-color: @text-muted;
@dropdown-header-color: @text-muted;
@dropdown-caret-color: rgb(0, 0, 0);
@navbar-default-color: @text-color;
@navbar-default-bg: @gray-dark;
@navbar-default-border: darken(@navbar-default-bg, 6.5%);
@navbar-default-link-color: @text-color;
@navbar-default-link-hover-color: rgb(255, 255, 255);
@navbar-default-link-hover-bg: darken(@navbar-default-bg, 8%);
@navbar-default-link-active-color: rgb(255, 255, 255);
@navbar-default-link-active-bg: darken(@navbar-default-bg, 8%);
@navbar-default-link-disabled-color: rgb(204, 204, 204);
@navbar-default-link-disabled-bg: transparent;
@navbar-default-brand-color: @navbar-default-link-color;
@navbar-default-brand-hover-color: rgb(255, 255, 255);
@navbar-default-brand-hover-bg: none;
@navbar-default-toggle-hover-bg: darken(@navbar-default-bg, 8%);
@navbar-default-toggle-icon-bar-bg: @text-color;
@navbar-default-toggle-border-color: darken(@navbar-default-bg, 8%);
@navbar-inverse-color: rgb(204, 204, 204);
@navbar-inverse-bg: @brand-primary;
@navbar-inverse-border: darken(@navbar-inverse-bg, 10%);
@navbar-inverse-link-color: rgb(204, 204, 204);
@navbar-inverse-link-hover-color: rgb(255, 255, 255);
@navbar-inverse-link-hover-bg: darken(@navbar-inverse-bg, 12%);
@navbar-inverse-link-active-color: @navbar-inverse-link-hover-color;
@navbar-inverse-link-active-bg: darken(@navbar-inverse-bg, 12%);
@navbar-inverse-link-disabled-color: rgb(204, 204, 204);
@navbar-inverse-link-disabled-bg: transparent;
@navbar-inverse-brand-color: @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color: rgb(255, 255, 255);
@navbar-inverse-brand-hover-bg: none;
@navbar-inverse-toggle-hover-bg: darken(@navbar-inverse-bg, 12%);
@navbar-inverse-toggle-icon-bar-bg: rgb(255, 255, 255);
@navbar-inverse-toggle-border-color: darken(@navbar-inverse-bg, 12%);
@nav-link-hover-bg: lighten(@body-bg, 10%);
@nav-disabled-link-color: @gray-light;
@nav-disabled-link-hover-color: @gray-light;
@nav-tabs-border-color: darken(@gray-darker, 5%);
@nav-tabs-link-hover-border-color: darken(@gray-darker, 5%);
@nav-tabs-active-link-hover-bg: lighten(@body-bg, 10%);
@nav-tabs-active-link-hover-color: rgb(255, 255, 255);
@nav-tabs-active-link-hover-border-color: darken(@gray-darker, 5%);
@nav-tabs-justified-link-border-color: @nav-tabs-border-color;
@nav-tabs-justified-active-link-border-color: @body-bg;
@nav-pills-active-link-hover-bg: @component-active-bg;
@nav-pills-active-link-hover-color: @component-active-color;
@pagination-color: @link-color;
@pagination-bg: @btn-default-bg;
@pagination-border: rgba(0, 0, 0, 0.6);
@pagination-hover-color: @link-hover-color;
@pagination-hover-bg: transparent;
@pagination-hover-border: rgba(0, 0, 0, 0.6);
@pagination-active-color: rgb(150, 150, 150);
@pagination-active-bg: darken(@pagination-bg, 10%);
@pagination-active-border: rgba(0, 0, 0, 0.6);
@pagination-disabled-color: @gray-light;
@pagination-disabled-bg: rgb(25, 25, 25);
@pagination-disabled-border: rgba(0, 0, 0, 0.6);
@pager-bg: @pagination-bg;
@pager-border: @pagination-border;
@pager-hover-bg: @pagination-hover-bg;
@pager-active-bg: @pagination-active-bg;
@pager-active-color: @pagination-active-color;
@pager-disabled-color: @gray-light;
@jumbotron-color: inherit;
@jumbotron-bg: darken(@body-bg, 5%);
@jumbotron-heading-color: inherit;
@state-success-text: rgb(255, 255, 255);
@state-success-bg: @brand-success;
@state-success-border: darken(spin(@state-success-bg, -10), 5%);
@state-info-text: rgb(255, 255, 255);
@state-info-bg: @brand-info;
@state-info-border: darken(spin(@state-info-bg, -10), 7%);
@state-warning-text: rgb(255, 255, 255);
@state-warning-bg: @brand-warning;
@state-warning-border: darken(spin(@state-warning-bg, -10), 3%);
@state-danger-text: rgb(255, 255, 255);
@state-danger-bg: @brand-danger;
@state-danger-border: darken(spin(@state-danger-bg, -10), 3%);
@tooltip-color: rgb(255, 255, 255);
@tooltip-bg: rgba(0, 0, 0, 0.9);
@tooltip-opacity: .9;
@tooltip-arrow-color: @tooltip-bg;
@popover-bg: lighten(@body-bg, 3%);
@popover-border-color: rgba(0, 0, 0, 0.2);
@popover-fallback-border-color: rgb(153, 153, 153);
@popover-title-bg: @popover-bg;
@popover-arrow-color: @popover-bg;
@popover-arrow-outer-color: fadein(@popover-border-color, 5%);
@popover-arrow-outer-fallback-color: darken(@popover-fallback-border-color, 20%);
@label-default-bg: @btn-default-bg;
@label-primary-bg: @brand-primary;
@label-success-bg: @brand-success;
@label-info-bg: @brand-info;
@label-warning-bg: @brand-warning;
@label-danger-bg: @brand-danger;
@label-color: rgb(255, 255, 255);
@label-link-hover-color: rgb(255, 255, 255);
@alert-success-bg: @state-success-bg;
@alert-success-text: @state-success-text;
@alert-success-border: @state-success-border;
@alert-info-bg: @state-info-bg;
@alert-info-text: @state-info-text;
@alert-info-border: @state-info-border;
@alert-warning-bg: @state-warning-bg;
@alert-warning-text: @state-warning-text;
@alert-warning-border: @state-warning-border;
@alert-danger-bg: @state-danger-bg;
@alert-danger-text: @state-danger-text;
@alert-danger-border: @state-danger-border;
@progress-bg: darken(@body-bg, 5%);
@progress-bar-color: rgb(255, 255, 255);
@progress-bar-bg: @brand-primary;
@progress-bar-success-bg: @brand-success;
@progress-bar-warning-bg: @brand-warning;
@progress-bar-danger-bg: @brand-danger;
@progress-bar-info-bg: @brand-info;
@list-group-bg: lighten(@body-bg, 5%);
@list-group-border: rgba(0, 0, 0, 0.6);
@list-group-hover-bg: lighten(@body-bg, 10%);
@list-group-active-color: @component-active-color;
@list-group-active-bg: lighten(@body-bg, 10%);
@list-group-active-border: @list-group-border;
@list-group-active-text-color: lighten(@list-group-active-bg, 40%);
@list-group-disabled-color: @gray-light;
@list-group-disabled-bg: @gray-lighter;
@list-group-disabled-text-color: @list-group-disabled-color;
@list-group-link-color: @text-color;
@list-group-link-hover-color: @list-group-link-color;
@list-group-link-heading-color: rgb(255, 255, 255);
@panel-bg: lighten(@body-bg, 3%);
@panel-inner-border: rgba(0, 0, 0, 0.6);
@panel-footer-bg: @panel-default-heading-bg;
@panel-default-text: @text-color;
@panel-default-border: rgba(0, 0, 0, 0.6);
@panel-default-heading-bg: lighten(@body-bg, 10%);
@panel-primary-text: rgb(255, 255, 255);
@panel-primary-border: @panel-default-border;
@panel-primary-heading-bg: @brand-primary;
@panel-success-text: @state-success-text;
@panel-success-border: @panel-default-border;
@panel-success-heading-bg: @state-success-bg;
@panel-info-text: @state-info-text;
@panel-info-border: @panel-default-border;
@panel-info-heading-bg: @state-info-bg;
@panel-warning-text: @state-warning-text;
@panel-warning-border: @panel-default-border;
@panel-warning-heading-bg: @state-warning-bg;
@panel-danger-text: @state-danger-text;
@panel-danger-border: @panel-default-border;
@panel-danger-heading-bg: @state-danger-bg;
@thumbnail-bg: @well-bg;
@thumbnail-border: @well-border;
@thumbnail-caption-color: @text-color;
@well-bg: darken(@body-bg, 5%);
@well-border: darken(@well-bg, 7%);
@badge-color: rgb(255, 255, 255);
@badge-link-hover-color: rgb(255, 255, 255);
@badge-bg: @gray-light;
@badge-active-color: @link-color;
@badge-active-bg: @gray-light;
@breadcrumb-bg: transparent;
@breadcrumb-color: rgb(204, 204, 204);
@breadcrumb-active-color: @gray-light;
@carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
@carousel-control-color: rgb(255, 255, 255);
@carousel-control-opacity: .5;
@carousel-indicator-active-bg: rgb(255, 255, 255);
@carousel-indicator-border-color: rgb(255, 255, 255);
@carousel-caption-color: rgb(255, 255, 255);
@close-color: rgb(0, 0, 0);
@close-text-shadow: 0 1px 0 #fff;
@code-color: rgb(199, 37, 78);
@code-bg: rgb(249, 242, 244);
@kbd-color: rgb(255, 255, 255);
@kbd-bg: rgb(51, 51, 51);
@pre-bg: @gray-dark;
@pre-color: rgb(245, 245, 245);
@pre-border-color: rgb(204, 204, 204);

@modal-content-bg: lighten(@body-bg, 3%);
@modal-content-border-color: rgba(0, 0, 0, 0.2);
@modal-content-fallback-border-color: rgb(153, 153, 153);
@modal-backdrop-bg: rgb(0, 0, 0);
@modal-backdrop-opacity: .5;
@modal-header-border-color: darken(@gray-darker, 5%);
@modal-footer-border-color: @modal-header-border-color;

@text-muted: @gray-light;
@abbr-border-color: @gray-light;
@headings-small-color: @gray-light;
@blockquote-small-color: @gray-light;
@blockquote-border-color: @gray-light;
@page-header-border-color: darken(@gray-darker, 5%);
@hr-border: darken(@gray-darker, 5%);

@block-overlay-bg: rgb(0, 0, 0);
@block-message-bg: @gray-lighter;
@block-message-color: darken(white, 10%);

@input-group-addon-color: #fff;
@switch-color: @gray-dark;

@chart-text-color: @text-color;
@chart-bar-color: #1d3f1d;
@chart-bar-color-alt: lighten(@chart-bar-color, 10%);
//@chart-bar-color: darken(@gray-light, 10%);
//@chart-bar-color-alt: lighten(@gray-light, 10%);

@pie-color-1: #7f7400;
@pie-color-2: #7f4d06;
@pie-color-3: #7f2f25;
@pie-color-4: #185255;
@pie-color-5: #7f245e;
@pie-color-6: #71447f;
@pie-color-7: #381960;
@pie-color-8: #167d49;
@pie-color-9: #7f2f25;
@pie-color-10: #4f8d68;
@pie-color-11: #68829e;
@pie-color-12: #007744;
@pie-color-13: #919509;
@pie-color-14: #2c9586;
@pie-color-15: #105a13;
@pie-color-16: #503454;
@pie-color-17: #061859;
