@import (multiple) "../mixins/charts.less";

.nv-pieLabels > .nv-label > text {
  fill: rgb(230, 230, 230) !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g > text {
  fill: rgb(230, 230, 230) !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(1) > circle {
  fill: @pie-color-1 !important;
  stroke: @pie-color-1 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(2) > circle {
  fill: @pie-color-2 !important;
  stroke: @pie-color-2 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(3) > circle {
  fill: @pie-color-3 !important;
  stroke: @pie-color-3 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(4) > circle {
  fill: @pie-color-4 !important;
  stroke: @pie-color-4 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(5) > circle {
  fill: @pie-color-5 !important;
  stroke: @pie-color-5 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(6) > circle {
  fill: @pie-color-6 !important;
  stroke: @pie-color-6 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(7) > circle {
  fill: @pie-color-7 !important;
  stroke: @pie-color-7 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(8) > circle {
  fill: @pie-color-8 !important;
  stroke: @pie-color-8 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(9) > circle {
  fill: @pie-color-9 !important;
  stroke: @pie-color-9 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(10) > circle {
  fill: @pie-color-10 !important;
  stroke: @pie-color-10 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(11) > circle {
  fill: @pie-color-11 !important;
  stroke: @pie-color-11 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(12) > circle {
  fill: @pie-color-12 !important;
  stroke: @pie-color-12 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(13) > circle {
  fill: @pie-color-13 !important;
  stroke: @pie-color-13 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(14) > circle {
  fill: @pie-color-14 !important;
  stroke: @pie-color-14 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(15) > circle {
  fill: @pie-color-15 !important;
  stroke: @pie-color-15 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(16) > circle {
  fill: @pie-color-16 !important;
  stroke: @pie-color-16 !important;
}

g.nv-pieChart > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(17) > circle {
  fill: @pie-color-17 !important;
  stroke: @pie-color-17 !important;
}

.nv-pie > .nv-slice:nth-child(1) {
  fill: @pie-color-1;
}

.nv-pie > .nv-slice:nth-child(2) {
  fill: @pie-color-2;
}

.nv-pie > .nv-slice:nth-child(3) {
  fill: @pie-color-3;
}

.nv-pie > .nv-slice:nth-child(4) {
  fill: @pie-color-4;
}

.nv-pie > .nv-slice:nth-child(5) {
  fill: @pie-color-5;
}

.nv-pie > .nv-slice:nth-child(6) {
  fill: @pie-color-6;
}

.nv-pie > .nv-slice:nth-child(7) {
  fill: @pie-color-7;
}

.nv-pie > .nv-slice:nth-child(8) {
  fill: @pie-color-8;
}

.nv-pie > .nv-slice:nth-child(9) {
  fill: @pie-color-9;
}

.nv-pie > .nv-slice:nth-child(10) {
  fill: @pie-color-10;
}

.nv-pie > .nv-slice:nth-child(11) {
  fill: @pie-color-11;
}

.nv-pie > .nv-slice:nth-child(12) {
  fill: @pie-color-12;
}

.nv-pie > .nv-slice:nth-child(13) {
  fill: @pie-color-13;
}

.nv-pie > .nv-slice:nth-child(14) {
  fill: @pie-color-14;
}

.nv-pie > .nv-slice:nth-child(15) {
  fill: @pie-color-15;
}

.nv-pie > .nv-slice:nth-child(16) {
  fill: @pie-color-16;
}

.nv-pie > .nv-slice:nth-child(17) {
  fill: @pie-color-17;
}

.generate-multibar-colors(40);

.generate-week-colors(7);

.generate-day-colors(24);
